/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, {useRef, useEffect, forwardRef, useImperativeHandle} from 'react';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {LexicalErrorBoundary} from '@lexical/react/LexicalErrorBoundary';
import {ListPlugin} from '@lexical/react/LexicalListPlugin';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {ListNode, ListItemNode} from '@lexical/list';
import {ParagraphNode, $createParagraphNode, $createTextNode} from 'lexical';
import ToolbarPlugin from './ToolbarPlugin';
import {parseLexicalState} from './parseLexicalState';
import {RichTextEditorProps} from '../../../types';
import {Icon} from '../index';
import styles from './RichTextEditor.module.scss';

const editorConfig = {
  namespace: 'RichTextEditor',
  theme: {
    list: {
      nested: {
        listitem: styles.editorListNestedItem,
      },
      ul: styles.editorListUl,
      listitem: styles.editorListUlItem,
    },
    paragraph: styles.editorParagraph,
    text: {
      bold: styles.editorTextBold,
      italic: styles.editorTextItalic,
    },
  },
  nodes: [ListNode, ListItemNode, ParagraphNode],
  onError: (error: Error) => console.error('Editor Error:', error),
};

// Custom plugin to handle the editor instance
const EditorInstancePlugin: React.FC<{
  onEditorReady: (editor: any) => void;
}> = ({onEditorReady}) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (editor) {
      onEditorReady(editor);
    }
  }, [editor, onEditorReady]);

  return null;
};

const RichTextEditor = forwardRef<
  {
    getEditorState: () => string;
  },
  RichTextEditorProps
>(
  (
    {
      className = '',
      variant,
      label,
      labelIconProps,
      placeholder = '',
      errorMessage,
      showErrorMessage = false,
      onChange,
      value,
    },
    ref
  ) => {
    const editorRef = useRef<any>(null);
    const hasInitialized = useRef(false); // Used to prevent re-initialization of the editor content
    const {t} = useTranslation();

    const handleContentChange = (editorState: unknown) => {
      const serializedState = JSON.stringify(editorState);
      editorRef.current = serializedState; // Store serialized state internally
      onChange(serializedState);
    };

    const loadInitialContent = (editor: any) => {
      if (value && !hasInitialized.current) {
        const lexicalState = parseLexicalState(value);

        if (lexicalState) {
          // If valid Lexical state, set it into the editor
          const editorState = editor.parseEditorState(lexicalState);
          editor.setEditorState(editorState);
        } else {
          // Fallback for plain text
          editor.update(() => {
            const root = editor.getEditorState()._nodeMap.get('root');
            if (root) {
              root.clear(); // Clear the existing content
              const paragraphNode = $createParagraphNode();
              paragraphNode.append($createTextNode(value));
              root.append(paragraphNode);
            }
          });
        }
        hasInitialized.current = true;
      }
    };

    // Expose getEditorState via ref
    useImperativeHandle(ref, () => ({
      getEditorState: () => {
        return editorRef.current || '';
      },
    }));

    return (
      <div
        className={classnames(
          className,
          styles.wrapper,
          styles[`wrapper__${variant}`]
        )}
      >
        <div className={styles.inputField}>
          <LexicalComposer initialConfig={editorConfig}>
            <EditorInstancePlugin onEditorReady={loadInitialContent} />
            <div className={styles.labelToolbarWrapper}>
              <label className={styles.inputLabel}>
                {t(label)}
                {labelIconProps?.labelIcon && (
                  <Icon
                    name={labelIconProps.labelIcon}
                    className={labelIconProps.labelIconClassName}
                    tooltip={labelIconProps.labelIconTooltipContent}
                    tooltipPosition={
                      labelIconProps.labelIconTooltipContentPosition
                    }
                    tooltipClasses={labelIconProps.labelIconTooltipClasses}
                    hasTooltipArrow={labelIconProps.labelIconTooltipArrow}
                  />
                )}
              </label>
              <ToolbarPlugin />
            </div>
            <RichTextPlugin
              contentEditable={
                <ContentEditable
                  className={classnames(styles.editor, {
                    [styles.inputInvalid]: showErrorMessage,
                  })}
                />
              }
              placeholder={
                <div className={styles.placeholder}>{placeholder}</div>
              }
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <ListPlugin />
            <OnChangePlugin onChange={handleContentChange} />
          </LexicalComposer>
          {errorMessage && showErrorMessage && (
            <span className={styles.errorMessage}>{errorMessage}</span>
          )}
        </div>
      </div>
    );
  }
);

export default RichTextEditor;
