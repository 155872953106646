import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FeatureFlags} from '../types';

interface FeatureFlagsSlice {
  data: FeatureFlags;
}

const initialState: FeatureFlagsSlice = {
  data: {
    releaseDataCollectionEnabled: false,
    releaseDocumentRepositoryEnabled: false,
    releaseEsgEnabled: false,
    releaseOwnershipControlEnabled: false,
    releaseAiNewsEnabled: false,
    releaseNearCastingEnabled: false,
    releaseAttachDocumentsToDataEnabled: false,
    releaseAskAiEnabled: false,
    releaseInFlightModeEnabled: false,
    releaseCompanyDataVisualisationEnabled: false,
    releaseFilePreviewEnabled: false,
    releasePersonalisedVisualisationEnabled: false,
    ga120FundOverviewPage: false,
    ga254FundsListPage: false,
    ga396FundOperationalPage: false,
    releaseForecastEnabled: false,
    releaseInvestmentCommentary: false,
    assureRedirect: false,
    releaseCalculatedDataEnabled: false,
    beac333EnableUsers: false,
    em238InvestmentCommentaryEnabled: false,
  },
};

const featureFlagsSlice = createSlice({
  name: 'feature-flags',
  initialState,
  reducers: {
    setFeatureFlags(
      state: FeatureFlagsSlice,
      action: PayloadAction<FeatureFlags>
    ) {
      state.data = action.payload;
    },
  },
});

export const {setFeatureFlags} = featureFlagsSlice.actions;
export default featureFlagsSlice.reducer;
