import {EditorState} from 'lexical';

export const parseLexicalState = (value: string): EditorState | null => {
  try {
    // Attempt to parse the value as JSON
    const parsedState = JSON.parse(value);

    // Check if the parsed object has the structure of a Lexical state
    if (
      parsedState &&
      typeof parsedState === 'object' &&
      parsedState.root &&
      Array.isArray(parsedState.root.children)
    ) {
      return parsedState; // Return the parsed Lexical state
    }
  } catch (error) {
    if (error instanceof Error) {
      console.warn('Failed to parse Lexical state:', error.message);
    } else {
      console.warn('Failed to parse Lexical state:', error);
    }
  }

  return null;
};
