import React, {Fragment, useEffect, useRef} from 'react';
import classnames from 'classnames';
import routePaths from '../../../routePaths';
import {Func, UserRole} from '../../../types';
import {useOnOutsideClick} from '../../../hooks';
import {Link, NavLink, useLocation} from 'react-router-dom';
import {NavLogo} from '../index';
import {Icon} from '../../global';
import styles from './MobileNavMenu.module.scss';
import {Trans, useTranslation} from 'react-i18next';

interface MobileNavMenuProps {
  isMenuOpen: boolean;
  onMenuClose: Func<[], void>;
  userName?: string;
  userMail?: string;
  userRole?: UserRole;
  userImage?: string;
  onUserLogOut?: Func<[], void>;
  isAdmin: boolean;
  isCompanyAdmin: boolean;
  releaseDataCollectionEnabled: any;
  releaseDocumentRepositoryEnabled: any;
  em238InvestmentCommentaryEnabled: any;
}

const MobileNavMenu: React.FC<MobileNavMenuProps> = ({
  isMenuOpen,
  onMenuClose,
  userImage,
  userMail,
  userRole,
  userName,
  onUserLogOut,
  isAdmin,
  isCompanyAdmin,
  releaseDataCollectionEnabled,
  releaseDocumentRepositoryEnabled,
  em238InvestmentCommentaryEnabled,
}) => {
  const {t} = useTranslation();
  const location = useLocation();
  const menuRef = useRef<HTMLDivElement>(null);

  const getLinkClassName = (isActive: boolean) =>
    classnames(styles.link, isActive && styles.active);

  const handleClickUserLogOut = (event: React.MouseEvent) => {
    event.preventDefault();
    if (onUserLogOut) {
      onUserLogOut();
    }
  };

  useOnOutsideClick(menuRef, onMenuClose);

  useEffect(() => {
    onMenuClose();
  }, [location.pathname]);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isMenuOpen]);

  return (
    <Fragment>
      <div
        className={classnames(styles.overlay, isMenuOpen && styles.isOpen)}
      />
      <div
        className={classnames(styles.wrapper, isMenuOpen && styles.isOpen)}
        ref={menuRef}
      >
        <div className={styles.top}>
          <button className={styles.closeButton} onClick={onMenuClose}>
            <Icon className={styles.closeButtonIcon} name={'cross'} />
          </button>
          <NavLogo
            logoGradientId={'mobile-navigation'}
            className={styles.logo}
            linkTo={isAdmin ? routePaths.HOME : routePaths.REPORTING}
          />
        </div>
        <ul className={styles.navMenu}>
          {/*
            Internal admin and user nav links
          */}
          {isAdmin ? (
            <Fragment>
              <li className={classnames(styles.listItem)}>
                <NavLink
                  exact
                  to={routePaths.HOME}
                  className={getLinkClassName}
                  data-test="navigation-mobile-home-link"
                >
                  <Icon className={styles.icon} name={'home'} />
                  <span>{t('NavigationMenu.Home')}</span>
                </NavLink>
              </li>
              <li className={classnames(styles.listItem)}>
                <NavLink
                  to={routePaths.FUNDS}
                  className={(isActive: boolean) =>
                    getLinkClassName(
                      isActive ||
                        location.pathname.includes('/scenarios/funds/')
                    )
                  }
                  data-test="navigation-mobile-funds-link"
                >
                  <Icon className={styles.icon} name={'funds'} />
                  <span>{t('NavigationMenu.Funds')}</span>
                </NavLink>
              </li>
              <li className={styles.listItem}>
                <NavLink
                  aria-disabled
                  to={routePaths.PORTFOLIO_COMPANIES}
                  className={getLinkClassName}
                  data-test="navigation-mobile-portfolios-link"
                >
                  <Icon className={styles.icon} name={'companies'} />
                  <span>{t('Global.Portfolio')}</span>
                </NavLink>
              </li>
              {releaseDataCollectionEnabled ? (
                <li className={styles.listItem}>
                  <NavLink
                    aria-disabled
                    to={routePaths.DATA_COLLECTION}
                    className={() =>
                      getLinkClassName(
                        location.pathname.includes(
                          routePaths.DATA_COLLECTION
                        ) &&
                          !location.pathname.includes(
                            routePaths.ADMIN_DATA_COLLECTION
                          )
                      )
                    }
                    data-test="navigation-mobile-data-collection-link"
                  >
                    <Icon className={styles.icon} name={'data-collection'} />
                    <span>
                      <Trans i18nKey="NavigationMenu.DataCollection" />
                    </span>
                  </NavLink>
                </li>
              ) : null}
              {releaseDocumentRepositoryEnabled ? (
                <li className={styles.listItem}>
                  <NavLink
                    to={routePaths.DOCUMENTS_DOCUMENT_EXPLORER}
                    className={getLinkClassName}
                    data-test="navigation-mobile-documents-link"
                  >
                    <Icon className={styles.icon} name={'document'} />
                    <span>{t('Global.Documents')}</span>
                  </NavLink>
                </li>
              ) : null}
              {em238InvestmentCommentaryEnabled ? (
                <li className={styles.listItem}>
                  <a
                    href={routePaths.AI_INVESTMENT_COMMENTARY}
                    className={getLinkClassName(false)}
                    data-test="navigation-investment-commentary-link"
                  >
                    <Icon name={'ai-commentary'} className={styles.icon} />
                    <span>{t('NavigationMenu.AIInvestmentCommentary')}</span>
                  </a>
                </li>
              ) : null}
            </Fragment>
          ) : null}

          {/*
            Company admin nav links
          */}
          {isCompanyAdmin ? (
            <>
              {releaseDataCollectionEnabled && (
                <li className={styles.listItem}>
                  <NavLink
                    to={routePaths.REPORTING}
                    className={getLinkClassName}
                  >
                    <Icon className={styles.icon} name={'data-collection'} />
                    <span>{t('NavigationMenu.Reporting')}</span>
                  </NavLink>
                </li>
              )}
              {releaseDocumentRepositoryEnabled && (
                <li className={styles.listItem}>
                  <NavLink
                    to={routePaths.DOCUMENTS_DOCUMENT_EXPLORER}
                    className={getLinkClassName}
                  >
                    <Icon className={styles.icon} name={'document'} />
                    <span>{t('Global.Documents')}</span>
                  </NavLink>
                </li>
              )}
            </>
          ) : null}

          {/*
            @TODO As a part of ticket LP-1285 we'll remove NavLinks to the pages that are not developed currently until they development is planed and finished.
         */}
          {/*  <hr />*/}
          {/*  <li className={styles.listItem}>*/}
          {/*    <NavLink*/}
          {/*      to={'#'}*/}
          {/*      className={classnames(styles.link, styles.disabled)}*/}
          {/*      onClick={e => e.preventDefault()}*/}
          {/*    >*/}
          {/*      <Icon name={'tasks'} />*/}
          {/*      <span>Tasks</span>*/}
          {/*    </NavLink>*/}
          {/*  </li>*/}
          {/*  <li className={styles.listItem}>*/}
          {/*    <NavLink*/}
          {/*      to={'#'}*/}
          {/*      className={classnames(styles.link, styles.disabled)}*/}
          {/*      onClick={e => e.preventDefault()}*/}
          {/*    >*/}
          {/*      <Icon name={'shortcuts'} />*/}
          {/*      <span>Shortcuts</span>*/}
          {/*    </NavLink>*/}
          {/*  </li>*/}
          {/*  <li className={styles.listItem}>*/}
          {/*    <NavLink*/}
          {/*      to={'#'}*/}
          {/*      className={classnames(styles.link, styles.disabled)}*/}
          {/*      onClick={e => e.preventDefault()}*/}
          {/*    >*/}
          {/*      <Icon name={'activity'} />*/}
          {/*      <span>Activity</span>*/}
          {/*    </NavLink>*/}
          {/*  </li>*/}
          {/*  <li className={styles.listItem}>*/}
          {/*    <NavLink*/}
          {/*      to={'#'}*/}
          {/*      className={classnames(styles.link, styles.disabled)}*/}
          {/*      onClick={e => e.preventDefault()}*/}
          {/*    >*/}
          {/*      <Icon name={'news'} />*/}
          {/*      <span>News</span>*/}
          {/*    </NavLink>*/}
          {/*  </li>*/}
        </ul>
        <div className={styles.userInfo}>
          <div className={styles.userImage}>
            {userImage ? (
              <img src={userImage} alt="" />
            ) : (
              <Icon className={styles.userIcon} name={'user'} />
            )}
          </div>
          <ul className={styles.userList}>
            <li>
              <div className={styles.userName}>
                {t('NavigationMenu.Greeting', {userName})}
              </div>
              <div className={styles.userEmail}>{userMail}</div>
            </li>
            {userRole === UserRole.ClientAdmin ? (
              <Fragment>
                <li>
                  <Link to={routePaths.ADMIN_USER_USERS} onClick={onMenuClose}>
                    {t('Global.UserAdministration')}
                  </Link>
                </li>
                <li>
                  <Link
                    to={routePaths.ADMIN_DATA_COLLECTION_TEMPLATE_CONFIGURATION}
                    onClick={onMenuClose}
                  >
                    {t('DataCollectionAdministration.Label')}
                  </Link>
                </li>
              </Fragment>
            ) : null}
            <li onClick={onUserLogOut} className={styles.clickable}>
              <button onClick={handleClickUserLogOut}>
                {t('NavigationMenu.LogOut')}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default MobileNavMenu;
