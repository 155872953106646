import React, {useState, useEffect, memo, Fragment, useMemo} from 'react';
import classnames from 'classnames';
import {Link, useLocation} from 'react-router-dom';
import {getBreadcrumbs} from '../../../utils/breadcrumbs';
import {useCompanyDetails, useFundsDetails} from '../../../hooks/';
import {
  BreadCrumbRoutes,
  BreadCrumbsNearCastingInfo,
} from '../../../types/index';
import {Icon} from '../../';
import styles from './Breadcrumbs.module.scss';
import {useTranslation} from 'react-i18next';
import {useAppSelector} from '../../../hooks/useReduxHooks';
import {formatToHyphenatedString} from '../../../utils/strings';

const Breadcrumbs: React.FC = memo(() => {
  const {t} = useTranslation();
  const {pathname} = useLocation();
  const {companyName} = useCompanyDetails();
  const {fundName} = useFundsDetails();

  const {report, main, valuation, transactions} = useAppSelector(
    state => state.scenarioDetails
  );
  const scenarioOnePageReport = useAppSelector(
    state => state.scenarioOnePageReport.main?.data
  );

  const [breadcrumbs, setBreadcrumbs] = useState<Array<BreadCrumbRoutes>>([]);

  const info: BreadCrumbsNearCastingInfo = useMemo(
    () => ({
      fundId: (report?.data?.id || scenarioOnePageReport?.fund?.id) as string,
      fundName:
        report?.data?.fundName ||
        scenarioOnePageReport?.reportedData?.fundName ||
        '',
      scenarioName:
        main?.data?.scenarioName ||
        transactions?.data?.scenarioName ||
        valuation?.data?.scenarioName ||
        scenarioOnePageReport?.scenarioName ||
        '',
    }),
    [report, main, transactions, valuation, scenarioOnePageReport]
  );

  const isNearCastingRoute = pathname.includes('/scenarios/funds/');

  useEffect(() => {
    const crumbs = [...getBreadcrumbs(pathname, info)];
    setBreadcrumbs(crumbs);
  }, [pathname, info, isNearCastingRoute]);

  return (
    <nav className={styles.breadcrumbs}>
      <ol className={styles.breadcrumbList}>
        {breadcrumbs?.map((item, i) => {
          const {path, name, isActive} = item;
          return isActive ? (
            <li
              key={i}
              className={classnames(
                styles.breadcrumb,
                isActive && styles.isActive
              )}
              data-test={`breadcrumb-active-${formatToHyphenatedString(name)}`}
            >
              {t(name)}
            </li>
          ) : (
            <Fragment key={i}>
              <li className={styles.breadcrumb}>
                <Link
                  to={path}
                  className={styles.breadcrumbLink}
                  data-test={`breadcrumb-${formatToHyphenatedString(name)}`}
                >
                  {t(name)}
                </Link>
              </li>
              <Icon name={'chevron-right'} className={styles.iconWrapper} />
            </Fragment>
          );
        })}
        {breadcrumbs?.length &&
        !breadcrumbs[breadcrumbs.length - 1].isActive ? (
          <li className={classnames(styles.breadcrumb, styles.isActive)}>
            {info?.fundName || fundName || companyName}
          </li>
        ) : null}
      </ol>
    </nav>
  );
});

export default Breadcrumbs;
