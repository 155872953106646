/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {FORMAT_TEXT_COMMAND} from 'lexical';
import {$getSelection, $isRangeSelection} from 'lexical';
import {
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  ListNode,
} from '@lexical/list';
import {$getNearestNodeOfType} from '@lexical/utils';
import styles from './ToolbarPlugin.module.scss';
import IconButton from '../icon-button/IconButton';
import {ButtonSize} from '../../../types';

const ToolbarPlugin: React.FC = () => {
  const [editor] = useLexicalComposerContext();
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isList, setIsList] = useState(false);

  useEffect(() => {
    const updateToolbar = () => {
      editor.getEditorState().read(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          // Bold/italic detection
          setIsBold(selection.hasFormat('bold'));
          setIsItalic(selection.hasFormat('italic'));

          // List detection
          const anchorNode = selection.anchor.getNode();
          const nearestListNode = $getNearestNodeOfType(anchorNode, ListNode);
          setIsList(nearestListNode !== null);
        } else {
          // If there's no valid range selection, reset formatting states
          setIsBold(false);
          setIsItalic(false);
          setIsList(false);
        }
      });
    };

    const unregisterListener = editor.registerUpdateListener(() => {
      updateToolbar();
    });

    return () => {
      unregisterListener();
    };
  }, [editor]);

  const toggleList = () => {
    // Just dispatch the command; let the Lexical state drive `isList` changes.
    if (isList) {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    }
  };

  return (
    <div className={styles.toolbar}>
      <IconButton
        active={isBold}
        buttonSize={ButtonSize.Small}
        icon="toolbar-bold"
        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')}
      >
        Bold
      </IconButton>
      <IconButton
        active={isItalic}
        buttonSize={ButtonSize.Small}
        icon="toolbar-italic"
        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')}
      >
        Italic
      </IconButton>
      <IconButton
        active={isList}
        buttonSize={ButtonSize.Small}
        icon="toolbar-list-bullet"
        onClick={toggleList}
      >
        Bullet List
      </IconButton>
      {/* <button
        onClick={() => editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND)}
      >
        Ordered List
      </button> */}
    </div>
  );
};

export default ToolbarPlugin;
