import {$generateHtmlFromNodes} from '@lexical/html';
import {createEditor} from 'lexical';
import {ListNode, ListItemNode} from '@lexical/list';
import {ParagraphNode} from 'lexical';

/**
 * Converts deserialized Lexical data to an HTML string.
 *
 * @param {SerializedEditorState} lexicalData - The deserialized Lexical editor state.
 * @returns {string} - The resulting HTML string.
 */
const parseLexicalDataToHtml = (lexicalData: Record<string, any>): string => {
  if (!lexicalData) {
    throw new Error('Invalid Lexical data provided');
  }

  // Create a temporary Lexical editor instance with the required nodes
  const editor = createEditor({
    namespace: 'TemporaryEditor',
    nodes: [ListNode, ListItemNode, ParagraphNode],
  });

  // Parse the Lexical state and set it in the editor
  try {
    const editorState = editor.parseEditorState(
      typeof lexicalData === 'string'
        ? lexicalData
        : JSON.stringify(lexicalData)
    );
    editor.setEditorState(editorState);
  } catch (error) {
    if (error instanceof Error) {
      throw new Error('Failed to parse Lexical data: ' + error.message);
    } else {
      throw new Error('Failed to parse Lexical data: Unknown error');
    }
  }

  // Generate HTML from the nodes
  let htmlString = '';
  editor.update(() => {
    htmlString = $generateHtmlFromNodes(editor, null); // Use `null` for BaseSelection
  });

  return htmlString;
};

export default parseLexicalDataToHtml;
