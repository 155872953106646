import {t} from 'i18next';
import {Row} from '@tanstack/react-table';
import {
  FormStatus,
  Func,
  InvestmentCommentaryItem,
  InvestmentCommentaryItemRow,
} from '../../../../types';
import styles from './InvestmentCommentaryTableExpandedRow.module.scss';
import parseLexicalDataToHtml from '../../richtext/parseLexicalDataToHtml';
import {parseLexicalState} from '../../richtext/parseLexicalState';

export type InvestmentCommentaryTableExpandedRowProps<TData> = {
  row: Row<TData>;
  onSubmitForm: Func<[InvestmentCommentaryItem], void>;
  formStatus: FormStatus;
  isRequestPending: boolean;
};

const InvestmentCommentaryTableExpandedRow = ({
  row,
  onSubmitForm,
  formStatus,
  isRequestPending,
}: InvestmentCommentaryTableExpandedRowProps<InvestmentCommentaryItemRow>): JSX.Element => {
  // Validate Lexical state
  const lexicalState = parseLexicalState(row.original.description);

  // Generate HTML content only if lexicalState is valid
  const htmlContent = lexicalState
    ? parseLexicalDataToHtml(JSON.parse(row.original.description))
    : `<p>${row.original.description}</p>`;

  return (
    <div className={styles.wrapper}>
      <div className={styles.column}>
        <div className={styles.sectionTitle}>
          {t('Portfolio.Company.InvestmentCommentary.Commentary.Title') + ':'}
        </div>
        <div
          className={[styles.commentary, styles.richTextOutput].join(' ')}
          dangerouslySetInnerHTML={{
            __html: htmlContent,
          }}
        />
      </div>
    </div>
  );
};

export default InvestmentCommentaryTableExpandedRow;
